import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export const exportPrintMixin = {
  methods: {
    onExportXlsx() {
      $h.exportToXlsx(
        this.$refs.tableRef,
        this.exportTableName || 'Table',
        this.exportTableName || 'Table'
      )
    },
    async onExportAllXlsx() {
      await this.doExport({
        resource: this.exportResourceName,
        filter: this.filterInput
      })
    },
    onDownloadPdf() {
      $h.downloadPdf(
        '#tablePrint',
        this.exportTableName || 'Table',
        this.excludedCols
      )
    },
    onPrint() {
      print({
        printable: 'tablePrint',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 500,
        ignoreElements: this.ignoreElements
      })
    }
  }
}
